import React from 'react';

import styled from '@emotion/styled';
import { Section, Container } from './global';

export default function Features() {
  return (
    <Section id="features">
      <StyledContainer>
        <Subtitle>Features</Subtitle>
        <SectionTitle>Smart note taking</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureTitle>Note Extraction</FeatureTitle>
            <FeatureText>
              Extract the most important information from the meeting like purpose, participants,
              and takeaways.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Audio Recordings</FeatureTitle>
            <FeatureText>
              Keep the full audio recording from each meeting, with playback and download options.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Quick Search</FeatureTitle>
            <FeatureText>Quickly find a meeting by date, participants, or keywords.</FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Shareable</FeatureTitle>
            <FeatureText>
              Share notes with clients, stakeholders, and team members who missed the meeting.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Privacy</FeatureTitle>
            <FeatureText>
              Your notes are your notes. They will never be shared with other users.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Security</FeatureTitle>
            <FeatureText>
              We take security seriously. Data is always encrypted during transit and passwords are
              salted+hashed.
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </Section>
  );
}

const StyledContainer = styled(Container)``;

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`;

const FeatureText = styled.p`
  text-align: center;
`;

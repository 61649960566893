import styled from '@emotion/styled';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import MicWithSpeechBubble from '../images/mic-with-speech-bubble.svg';
import { Container } from './global';

export default function Header() {
  const [email, setEmail] = useState<string>('');

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Meeting Notes</Subtitle>
            <h1>
              Take notes,
              <br />
              without a note taker
            </h1>
            <h2>
              We&apos;re building next generation note taking tools. Sign up to get early access.
            </h2>
            <HeaderForm
              onSubmit={e => {
                e.preventDefault();

                navigate('/sign-up', {
                  state: {
                    email,
                  },
                });
              }}
            >
              <HeaderInput
                placeholder="Your email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <HeaderButton>Early access</HeaderButton>
            </HeaderForm>
            {/* <FormSubtitle>
              Already have a beta account? <FormSubtitleLink to="/login">Sign in</FormSubtitleLink>
            </FormSubtitle> */}
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledMicWithSpeechBubble style={{}} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 120px;
  }
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`;

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.fontSize.regular}
  }

  p {
    margin-bottom: 48px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 64px;
  }
`;

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`;

const FormSubtitle = styled.span`
  ${props => props.theme.fontSize.xxsmall}
`;

const FormSubtitleLink = styled(Link)`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
`;

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`;

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`;

const StyledMicWithSpeechBubble = styled(MicWithSpeechBubble)`
  width: 500px;
  height: 500px;
  margin-left: 50px;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
    height: 400px;
    margin-left: 0;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 250px;
    height: 250px;
  }
`;

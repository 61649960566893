import React from 'react';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import SEO from '../components/seo';
import Navigation from '../components/navigation';
import GetStarted from '../components/get-started';
import Features from '../components/features';

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <Navigation />
      <Header />
      <Features />
      <GetStarted />
      <Footer />
    </Layout>
  );
}
